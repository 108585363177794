.s-container {
    position: relative;
    bottom: 12.5rem;

}

.s-container .swiper {
    width: 70%;
    height: 12rem;
    position: unset;
}

.s-container .swiper-slide {
    background: #f1d4af;
    display: flex;
    border-radius: 10px;
    border: 1px solid var(--bordercolor);
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

}

.s-container .swiper-button-prev {
    left: 6rem;
    color: #d68189;
}

.s-container .swiper-button-next {
    right: 6rem;
    color: #d68189;

}

.swiper-pagination-bullet-active {
    background-color: #d68189 !important;

}

.left-s {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.name {
    display: flex;
    flex-direction: column;
}

.name>:nth-child(1) {
    font-size: 1.4rem;
    font-weight: 600;
}

.name>:nth-child(2) {
    font-size: 0.8rem;
}

.left-s>:nth-child(2) {
    font-weight: 800;
    font-size: 2rem;

}

.left-s>:nth-child(3) {
    font-size: 0.8rem;
    border: 1px solid var(--bordercolor);
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;

}

.img-p {
    transform: rotate(-20deg);
    position: absolute;
    right: 1rem;
    width: 30%;
    height: 100%;
    bottom: 0;
}

@media screen and (max-width: 856px) {
    .s-container .swiper {
        width: 90%;
        height: 10rem;
        top: 3rem;
        position: absolute;
    }

    .s-container .swiper-button-prev,
     .s-container .swiper-button-prev {
        display: none;
     }
}

.container {
    padding: 0 2rem;
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

}

.container>:nth-child(1) {
    position: absolute;
    width: 8rem;
    left: 30%;
    top: -4rem;
}

.Products {
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
}

.menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    font-weight: 500;
    font-size: 1.4rem;
}

.menu>li:hover {
    color: white;
    scale: 1.1;
    cursor: pointer;
}

.list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    height: 25rem;
    overflow-y: scroll;
    grid-gap: 1.5rem;
    justify-content: space-between;
}

.Product {
    width: 11rem;
    height: 10rem;
    background-color: #f1d4af;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;

}

.Product>img {
    top: 2rem;
    width: 5rem;
    height: 9.5rem;
}

@media screen and (max-width:856px) {
    .container {
        gap: 1rem;
    }

    .container>:nth-child(1) {
        /* display: none; */
        margin-top: -3rem;
    }

    .Product {
        grid-template-columns: none;
        gap: 1rem;
    }

    .menu {
        flex-direction: row;
    }


}

@media screen and (max-width: 640px) {
    .container {
        margin-top: 5rem;
    }

    .menu {
        margin-left: -2rem;
        margin-top: -5rem;
        margin-bottom: 5rem;
        gap: 0.5rem;
        flex-wrap: nowrap;
        width: 100%;
        font-size: 1.2rem;
        text-align: center;
    }

    .container h1 {
        text-align: center;
        font-size: 1rem;
        margin-bottom: 5rem
    }

}
.cFooterWrapper {
    width: 100%;
    margin-top: 5rem;
}

.cFooterWrapper>hr {
    width: 100%;
    height: 3px;
    border: none;
    background: rgb(255, 255, 255);
    margin-top: 1rem;
}

.cFooter {
    display: flex;
    width: 100%;
    justify-content: space-around;

}

.logo {
    display: flex;
    align-items: flex-start;
    gap: 1rem;

}

.logo>img {
    width: 5rem;
    height: 5rem;

}

.logo>span {
    font-weight: 800;
    font-size: 1.5rem;
}

.block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 12rem;
}

.detail {
    display: flex;
    flex-direction: column;
    width: inherit;
    font-size: 0.9rem;
    gap: 1rem;
    font-style: italic;
}

.detail>span:nth-of-type(1) {
    font-weight: 800;
    font-style: normal;
    font-size: 1.2rem;
}

.pngLine {
    display: flex;
    gap: 1rem;
}

.pngLine:hover {
    cursor: pointer;
}

.icon {
    width: 30px;
}

.copyRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-size: 0.7rem;
    margin-top: 2rem;
}

@media screen and (max-width: 768px) {
    .cFooter {
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 7rem;
        gap: 2rem;
    }
}

@media screen and (max-width: 640px) {
    .cFooter {
        justify-content: center;
    }

    .logo {
        display: none;
    }

}
.container {
    padding: 0rem 2rem;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -3rem;
    padding: 0.2rem;
}

.wrapper>img {
    width: 37rem;
    position: absolute;
    bottom: 0;
}

.blueCircle {
    width: 29rem;
    height: 23rem;
    border-radius: 5%;
    border: 1px solid rgb(143, 137, 137);
    box-shadow: var(--shadow3);
    z-index: -99;
    position: absolute;
    bottom: -1rem;
    background-color: #f1d4af;
    -webkit-border-radius: 5%;
    -moz-border-radius: 5%;
    -ms-border-radius: 5%;
    -o-border-radius: 5%;
}

.cart2 {
    position: relative;
    bottom: 0%;
    left: 23.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.cart2>svg {
    width: 25px;
    height: 25px;
    background: #f1d4af;
    padding: 10px;
    border-radius: 50%;
    border: 2px solid var(--bordercolor);

}

.signup {
    display: flex;
    gap: 1rem;
    align-items: center;
    background: #f1d4af;
    padding: 10px;
    border-radius: 15px;
    border: 1px solid var(--bordercolor);
    font-size: 0.8rem;
    box-shadow: var(--shadow1)
}

.signup>:first-child {}


.signup>:nth-child(2) {
    border-radius: 50%;
    border: 2px solid var(--bordercolor);
    display: flex;
    width: 20px;
    height: 20px;
    padding: 5px;
    align-items: center;
    justify-content: center;
}

.text1>hr {
    color: black;
    border: 1px solid black;
}

.h_sides {
    display: grid;
    grid-template-rows: 1fr 1fr;
    position: relative;
    padding: 1.3rem;
    gap: 1rem;
}

.text1 {
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    width: min-content;
    display: block;
    font-weight: 600;
    margin-top: 1.5rem;

}

.text2 {
    display: flex;
    flex-direction: column;
    width: fit-content;
    color: black;
    margin-top: 1.5rem;


}

.text2>:first-child {
    font-weight: 800;
    font-size: 2.1rem;
}

.text2>:nth-child(2) {
    display: block;
    width: 15rem;

}

.traffic {
    display: flex;
    flex-direction: column;
    text-align: right;
    bottom: 23rem;
    position: relative;
}


.traffic>:first-child {
    font-weight: 800;
    font-size: 2.5rem;
}

.customers {
    display: flex;
    flex-direction: column;
    text-align: right;
    bottom: 20rem;
    position: relative
}

.customers>:first-child {
    font-weight: 800;
    font-size: 2.5rem;
}

@media screen and (max-width: 856px) {
    .text1, .text2>span:nth-of-type(1) {
        font-size: 0.9rem;
        text-align: justify;
    }

    .text2>span :nth-of-type(1),
    .traffic>span:nth-child(1),
    .customers>span:nth-child(1) {
        font-size: 1.2rem;
        text-align: right;
    }

    .blueCircle {
        position: absolute;
        width: 55%;
        height: 250%;
    }

    .wrapper>img {
        width: 70%;
        /* height: 100%; */
    }

    .container {
        grid-template-areas:
            'left center center'
            'right right right';
    }

    .container>:first-child {
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem;
    }

    .container>:nth-child(2) {
        grid-area: center;
    }

    .container>:nth-child(3) {
        grid-area: right;
        display: flex;
        justify-content: space-around;
        margin: 2rem 0rem;
    }
}


@media screen and (max-width: 640px) {
    .blueCircle {
        bottom: 0;
        width: 13rem;
        height: 72%;
    }

    .wrapper>img {
        top: -10rem;
        width: 25rem;
        height: 15rem;
    }

    .cart2 {
        transform: scale(0.7);
        right: -2rem;
        bottom: 0;
    }
}
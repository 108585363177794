.container {
    padding: 1rem;
    display: flex;

}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    /* gap: 0.5rem; */
}

.logo>img {
    width: 4rem;
    margin-left: -30rem;
    position: absolute;
}



.logo>span {
    font-weight: 800;
    position: absolute;
    margin-right: 27rem;
    margin-top: 5rem;
    color: #d68189;
    font-size: 1.2rem;

}

.right {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;


}

.menu {
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;


}

.menu>li:hover {
    color: white;
    cursor: pointer;
    scale: 1.2;

}

.search {
    width: 7rem;
    outline: none;
    border: 0.5px solid rgb(114, 103, 103);
    border-radius: 5px;
    padding: 0.5rem;
    background-color: #f1d4af;
}

.cart {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.bars{
display: none;
}

@media screen and (max-width:856px) {
    .search {
        display: none;
    }

    .right {
        gap: 1rem
    }

}

@media screen and (max-width:640px) {
    .right {
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: white;
        color: var(--black);
        border-radius: 5px;
        flex-direction: column;
        padding: 1rem;
    }

    .menu {
        flex-direction: column;
        margin-left: -2rem;
        display: none;

    }

    .bars {
        display: block;
    }

    .search {
        display: none;
    }

}

:root {
  --black: #343434;
  --bg: hsl(351, 72%, 85%);
  --bg2: #d68189;
  --bordercolor: rgb(114, 103, 103);
  --shadow1: 0px 13px 48px -1px rgba(0, 0, 0, 0.25);
  --shadow2: 0px 13px 46px rgba(0, 0, 0, 0.13);
  --shadow3: 5px 13px 30px 1px rgba(14, 11, 5, 0.485);

  --pink: #FE956F;
}

/* #F8E367; */

.App {
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: skyblue;
  border-radius: 1ex;
}

a {
  text-decoration: none;
  color: inherit
}